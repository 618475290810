<template>
  <div class="content">
  <div class="contentbox">
    <el-tabs v-model="activeName">
      <el-tab-pane label="待审核" name="0">
        <ToAuditNew v-if="activeName==0" :hanleBtn="5"></ToAuditNew>
      </el-tab-pane>
      <el-tab-pane label="审核记录" name="1">
        <AuditRecordsNew v-if="activeName==1" :hanleBtn="5"></AuditRecordsNew>
      </el-tab-pane>
    </el-tabs>
  </div>
  </div>
</template>

<script>
import AuditRecordsNew from './components/auditRecordsNew'
import ToAuditNew from './components/toAuditNew'
export default {
  name: "storeIssue",
  components:{ AuditRecordsNew,ToAuditNew },
  data(){
    return{
      activeName:0
    }
  }
}
</script>

<style scoped>
.content{
  width: 100%;
  background: #f3f4f8;
}
.contentbox {
  margin: 10px;
  padding: 10px;
  background-color:#fff;
  position: relative;
}
</style>